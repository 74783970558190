<template>
    <div>
        <a href="tel:4932400973" class="btn d-flex align-items-center shadow-sm fs-17 justify-content-center fw-600 btn-outline-dark border-radius-20 px-25">
            <i class="fa fw-400 fa-phone-alt mr-2"></i> (49) 3240-0973
        </a>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>

</style>
